/*SocGeol stylesheet*/

html {
  font-family: "PT Sans", Arial, sans-serif;
  font-weight: 400 !important;
  color: #333;
}

body {
  background: #35291f !important;
}

h1 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1.5em !important;
  font-weight: 600 !important;
}
h2 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1.4em !important;
  font-weight: 600 !important;
}
h3 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1.3em !important;
}
h4 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1.1em !important;
}
h5 {
  font-family: "Barlow", Arial, sans-serif !important;
  font-size: 1em !important;
}

/*colors*/
.bg-darkbrown {
  background-color: #35291f !important;
}
.bg-brown {
  background-color: #482714 !important;
}
.bg-lightbrown {
  background-color: #988f76 !important;
}
.bg-darkorange {
  background-color: #6c3108 !important;
}
.bg-orange {
  background-color: #c57200 !important;
}
.bg-yellow {
  background-color: #eedb74 !important;
}
.bg-darkgreen {
  background-color: #333c1a !important;
}
.bg-green {
  background-color: #66883f !important;
}
.bg-darkgray {
  background-color: #45525d !important;
}
.bg-gray {
  background-color: #7a8491 !important;
}
.bg-lightgray {
  background-color: #adb6bb !important;
}
.bg-lightest {
  background-color: #dbdfe1 !important;
}

.txt-darkbrown {
  color: #35291f !important;
}
.txt-brown {
  color: #482714 !important;
}
.txt-lightbrown {
  color: #988f76 !important;
}
.txt-darkorange {
  color: #6c3108 !important;
}
.txt-orange {
  color: #c57200 !important;
}
.txt-yellow {
  color: #eedb74 !important;
}
.txt-darkgreen {
  color: #333c1a !important;
}
.txt-green {
  color: #66883f !important;
}
.txt-darkgray {
  color: #45525d !important;
}
.txt-gray {
  color: #7a8491 !important;
}
.txt-lightgray {
  color: #adb6bb !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #c57200 !important;
  border-color: #6c3108 !important;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: #e28506 !important;
  border-color: #6c3108 !important;
}
.btn-secondary {
  color: #fff !important;
  background-color: #6c4b1a !important;
  border-color: #482714 !important;
}
.btn-secondary:hover {
  color: #fff !important;
  background-color: #6e5735 !important;
  border-color: #482714 !important;
}
.btn-success {
  color: #fff !important;
  background-color: #66883f !important;
  border-color: #333c1a !important;
}
.btn-success:hover {
  color: #fff !important;
  background-color: #80a851 !important;
  border-color: #333c1a !important;
}

.text-shadow {
  text-shadow: 3px 3px 3px #000000;
}

.display {
  display: block;
}

.bold {
  font-weight: 600;
}

a {
  color: #c57200 !important;
  text-decoration: none !important;
  font-weight: 600;
}

a:hover {
  color: #e28506 !important;
  text-decoration: none !important;
}

/*///// CUSTOMIZATION //////*/

/*Overrides gerais*/

/*Navbar*/

.navbar-brand {
  max-height: 100px !important;
}

.navbar-nav {
  font-family: "Barlow", Arial, sans-serif !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1em !important;
  margin-left: 5%;
}

.nav-link {
  color: #ffffff !important;
  font-family: "Barlow", Arial, sans-serif !important;
  margin-right: 20px;
}

.nav-link:hover {
  color: #c57200 !important;
}

.dropdown-menu {
  background-color: #000 !important;
  color: white !important;
}

.dropdown-item {
  color: #ffffff !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #c57200 !important;
  color: #000000 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #c57200 !important;
  color: #000000 !important;
}

/*Carousel*/
.carousel-caption {
  bottom: 5rem !important;
}

/*Main*/
main {
  padding-top: 70px !important;
}

/*MEDIA QUERIES*/

@media only screen and (max-width: 575.98px) {
  .navbar-brand {
    max-height: 50px !important;
    margin-right: 3px !important;
    margin-bottom: 20px !important;
  }

  h1 {
    font-size: 0.9em !important;
  }

  .carousel-caption {
    display: none !important;
  }
}

/*Small devices - landscape phones, less than 768px*/
@media only screen (max-width: 767.98px) {
  .carousel-caption {
    display: none !important;
  }
}

/*Medium devices - tablets, less than 992px*/
@media only screen and (min-width: 991.98px) {
  .navbar-brand {
    max-height: 70px !important;
  }

  h1 {
    font-size: 1em !important;
  }
}

/*Large devices - desktops, less than 1200px*/
@media only screen and (min-width: 1199.98px) {
  .navbar-brand {
    max-height: 80px !important;
  }

  .display {
    display: none !important;
  }

  .navbar-nav {
    font-size: 0.9em !important;
  }
}

/*Bigger devices*/
@media only screen and (min-width: 1600px) {
  .navbar-brand {
    max-height: 100px !important;
  }

  .display {
    display: block !important;
  }

  .navbar-nav {
    font-size: 1em !important;
  }

  h1 {
    font-size: 1.4em !important;
  }
}

/*Labs stylesheet*/

a {
  color: #66883f !important;
  text-decoration: none !important;
  font-weight: 600;
}

a:hover {
  color: #afca90 !important;
  text-decoration: none !important;
}

body {
  background: #afca90 !important;
}

/*///// CUSTOMIZATION //////*/

/*Overrides gerais*/

/*Navbar*/

.nav-link {
  color: #333 !important;
  font-family: "Barlow", Arial, sans-serif !important;
  margin-right: 20px;
}

.nav-link:hover {
  color: #66883f !important;
}

.dropdown-menu {
  background-color: #fff !important;
  color: #333 !important;
}

.dropdown-item {
  color: #333 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #66883f !important;
  color: #fff !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #66883f !important;
  color: #fff !important;
}

/*MEDIA QUERIES*/

@media only screen and (max-width: 575.98px) {
}

/*Small devices - landscape phones, less than 768px*/
@media only screen (max-width: 767.98px) {
}

/*Medium devices - tablets, less than 992px*/
@media only screen and (min-width: 991.98px) {
}

/*Large devices - desktops, less than 1200px*/
@media only screen and (min-width: 1199.98px) {
}

/*Bigger devices*/
@media only screen and (min-width: 1600px) {
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 1px;
  margin: 10px;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 1px;
  margin: 10px;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #eedb74;
  box-shadow: 0 -1px 0 rgb(0 0 0 / 1%);
}

.accordion:hover {
  font-weight: 800;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #000000;
  outline: 0;
  font-weight: 800;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 1%);
}

.card-img-sameh {
  width: 100%;
  min-height: 175px;
  height: 25vh;
  object-fit: cover;
}

.carousel-control-next,
.carousel-control-prev

/*, .carousel-indicators */
  {
  height: 520px;
  width: 40px;
  outline: black;
  background-size: 100%, 100%;
  background-image: none;
}

img.slide {
  width: 100%;
  height: 32vh;
  object-position: 10% 10%;
  object-fit: cover;
  max-height: 120vh;
}

.nav-link-tab {
  color: #000000 !important;
  font-family: "Barlow", Arial, sans-serif !important;
  margin: 2px;
  border: 1px solid #e1e3e7 !important;
  font-size: small;
}

#user-data-tab-profile,
#user-data-tab-quotes {
  color: #000000 !important;
}

.nv {
  color: #000000 !important;
}

.nv:active {
  color: #000000 !important;
}

.nv:hover {
  color: #c57200;
}

.link,
.link:hover {
  color: #c57200 !important;
  text-decoration: none !important;
  font-weight: 600;
  background-color: #f8f9fa;
}

.link:hover {
  background-color: #f8f9fa !important;
}

.link:active {
  color: #c57200 !important;
  background-color: #f8f9fa !important;
}

.link:visited {
  color: #c57200 !important;
  background-color: #f8f9fa !important;
}

.carousel-indicators {
  margin-bottom: 1.5rem;
}

.lower-caption {
  width: 100%;
  color: #fff;
  margin-left: 3px;
  margin-top: -28px;
  font-size: 0.9rem;
}

@media only screen and (min-width:600.98px) {
  .navbar-brand-facebook {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .special-lf {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  }
}

@media only screen and (max-width:600px) {
  .navbar-brand-facebook {
    flex-grow: 1;
    justify-content: flex-end;
  }
  .button-md {
    height: 52px;
  }
  .special-lf {
    max-height: 50px;
    visibility: visible;
    display: flex;
    flex-direction: row-reverse;
    margin-left: 20px;
    padding-bottom: 10px;
    flex-grow: 1;
  }
}